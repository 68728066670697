<template>
  <div class="Login_root">
    <v-form ref="form">
      <TextFieldInput
        :value="loginForm.email"
        placeholder="random@gmail.com"
        name="email"
        :rules="[...inputRules.required, ...inputRules.email]"
        label="email address"
        hasShadow
        @input="handleInput(arguments[0], 'email')"
        removeSpacesHandler
      />
      <Button
        fullWidth
        brightBtn
        size="large"
        text="reset password"
        @click="handleReset"
      />
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import inputRules from '@/utils/inputRules';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Button from '@/components/_common/FormElements/Button/Button';
import { validateForm } from '@/mixins/validateForm';

export default {
  components: {
    TextFieldInput,
    Button,
  },
  mixins: [validateForm],
  data: () => ({
    inputRules,
    loginForm: {
      email: '',
    },
  }),
  methods: {
    ...mapActions('user', ['resetPasswordSendLink']),
    handleInput(value, field) {
      this.loginForm[field] = value;
    },
    handleReset() {
      this.validateForm().then(() => {
        this.resetPasswordSendLink({ email: this.loginForm.email })
          .then(res => {
            console.log('res', res);
          })
          .catch(error => {
            console.log('error', error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Login_root {
  max-width: 400px;
  margin: 0 auto;
}

.Login_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 0 0 25px;
  div {
    flex: 0 1 auto;
    a {
      color: #2af3f3;
      text-decoration: none;
    }
  }
}
</style>
